import { Box } from '@mui/material';
import * as React from 'react';

import sunset from '../images/ruby_beach_sunset.jpg'

const Home = () => {

    return (
        <div
          style={{
            backgroundImage: `url(${sunset})`,
            minHeight: '100vh',
            minWidth: '100vw',
            backgroundSize: 'cover',
            // backgroundRepeat: 'no-repeat'
          }}
        >
            test t
        </div>
    )
}

export default Home;