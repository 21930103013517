import { AppBar, Typography, Toolbar } from '@mui/material';
import './App.css';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';

const Navbar = () => {
  return (
    <AppBar
      style={{
        background: 'rgba(255, 255, 255, 0.1)',
      }}
    >
      <Toolbar>
        <Typography
          noWrap
          component="a"
          href="/"
          sx={{
            fontFamily: 'bradley hand, cursive;`',
            // fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'white',
            textDecoration: 'none',
          }}
        >
          Chris + Suzanna
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <main>
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
          </Routes>
        </main>
      </header>
    </div>
  );
}

export default App;
